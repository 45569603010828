import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { CSVHelper, CSVData } from "../helpers/csvHelper";
import { ReviewHelper } from "../helpers/ReviewHelper";
import { ShortenHelper } from "../helpers/ShortenHelper"; // Import the new helper
import FileList from "./FileList";
import CSVTable from "./CSVTable";
import Notification from "./Notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { PhotoIcon } from "@heroicons/react/24/solid";

interface FileMetadata {
  _id: string;
  userId: string;
  filename: string;
  path: string;
  uploadedAt: string;
}

interface CompanyProfile {
  _id: string;
  companyName: string;
}

const CSVUpload: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<CSVData[]>([]);
  const [message, setMessage] = useState<string>("");
  const [files, setFiles] = useState<FileMetadata[]>([]);
  const [editedData, setEditedData] = useState<CSVData[]>([]);
  const [currentFilename, setCurrentFilename] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [loadingWriteIndex, setLoadingWriteIndex] = useState<number | null>(
    null
  );
  const [loadingSendIndex, setLoadingSendIndex] = useState<number | null>(null);
  const [loadingRowIndex, setLoadingRowIndex] = useState<number | null>(null);

  const [loadingReviewIndex, setLoadingReviewIndex] = useState<number | null>(
    null
  );
  const [loadingShortenIndex, setLoadingShortenIndex] = useState<number | null>(
    null
  );
  const [stopProcessing, setStopProcessing] = useState<boolean>(false);
  const [cityFiles, setCityFiles] = useState<string[]>([]);
  const [selectedCityFile, setSelectedCityFile] = useState<string>("");
  const [notification, setNotification] = useState<{
    type: "success" | "error";
    message: string;
    description?: string;
  } | null>(null);
  const [profiles, setProfiles] = useState<CompanyProfile[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(
    null
  );

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    fetchFiles();
    fetchCityFiles();
    fetchProfiles();
  }, [getAccessTokenSilently]);

  const fetchFiles = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get<FileMetadata[]>(`${serverUrl}/files`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
      setNotification({
        type: "error",
        message: "Failed to fetch files",
        description: "There was an error fetching the files from the server.",
      });
    }
  };

  const fetchCityFiles = () => {
    const cityFilesArray = ["cities/example-investor-data.csv"];
    setCityFiles(cityFilesArray);
  };

  const fetchProfiles = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get<CompanyProfile[]>(
        `${serverUrl}/company-profiles`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProfiles(response.data);
      if (response.data.length > 0) {
        setSelectedCompanyId(response.data[0]._id); // Set the first company profile as selected by default
      }
    } catch (error) {
      console.error("Error fetching company profiles:", error);
      setNotification({
        type: "error",
        message: "Failed to fetch company profiles",
        description:
          "There was an error fetching company profiles from the server.",
      });
    }
  };

  const getColumnKeys = (data: CSVData[]): string[] => {
    if (data.length === 0) return [];
    const keys = Object.keys(data[0]);
    const columnsToShow = [
      keys[4], // 1st column
      keys[26], // 13th column
      keys[42], // 14th column
      keys[74], // 24th column
      keys[75], // 25th column
      keys[76], // 26th column
    ];
    return columnsToShow.filter((key) => key !== undefined);
  };

  const handleFileUpload = async (file: File) => {
    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const parsedData = results.data as CSVData[];
        const modifiedData = parsedData.map((row: CSVData) => ({
          ...row,
          "Has Sent?": "",
          "Email Title": "",
          "Email Content": "",
          Send: "",
        }));

        const csv = Papa.unparse(modifiedData);
        const blob = new Blob([csv], { type: "text/csv" });
        const modifiedFile = new File([blob], file.name, { type: "text/csv" });

        const formData = new FormData();
        formData.append("file", modifiedFile);

        try {
          const token = await getAccessTokenSilently();
          const response = await axios.post(`${serverUrl}/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          });
          setMessage(response.data.message);
          fetchFiles(); // Refresh the list of files after uploading

          // Show success notification
          setNotification({
            type: "success",
            message: "File uploaded successfully!",
            description: `The file ${file.name} was uploaded and processed.`,
          });
        } catch (error) {
          console.error("Error uploading file:", error);
          setNotification({
            type: "error",
            message: "File upload failed",
            description: `There was an error uploading the file ${file.name}.`,
          });
        }
      },
    });
  };

  const handleWriteRowClick = async (row: CSVData, rowIndex: number) => {
    setLoadingRowIndex(rowIndex); // Set the current row as loading
    try {
      await handleWriteRow(row, rowIndex); // Execute the writing function
    } finally {
      setLoadingRowIndex(null); // Reset loading state after processing
    }
  };

  const handleFileLoad = (file: File) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const parsedData = results.data as CSVData[];
        const modifiedData = parsedData.map((row: CSVData) => ({
          ...row,
          "Has Sent?": "",
          "Email Title": "",
          "Email Content": "",
          Send: "",
        }));
        setData(modifiedData);
        setEditedData(modifiedData);
        setSelectedRows(new Set()); // Reset selected rows

        // Show success notification
        setNotification({
          type: "success",
          message: "File loaded successfully!",
          description: `The file ${file.name} was loaded and ready to be processed.`,
        });
      },
    });
  };

  const handleFileClick = async (filename: string) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${serverUrl}/file/${filename}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // Important
      });

      const reader = new FileReader();
      reader.onload = (event) => {
        const csv = event.target?.result;
        if (typeof csv === "string") {
          Papa.parse(csv, {
            header: true,
            complete: (results) => {
              const parsedData = results.data as CSVData[];
              const modifiedData = parsedData.map((row: CSVData) => ({
                ...row,
                "Has Sent?": row["Has Sent?"] || "",
                "Email Title": row["Email Title"] || "",
                "Email Content": row["Email Content"] || "",
                Send: row["Send"] || "",
              }));
              setData(modifiedData);
              setEditedData(modifiedData);
              setCurrentFilename(filename);
              setSelectedRows(new Set()); // Reset selected rows

              // Show success notification
              setNotification({
                type: "success",
                message: "File loaded successfully!",
                description: `The file ${filename} was loaded from the server.`,
              });
            },
          });
        }
      };
      reader.readAsText(response.data);
    } catch (error) {
      console.error("Error fetching file:", error);
      setNotification({
        type: "error",
        message: "Failed to load file",
        description: `There was an error loading the file ${filename}.`,
      });
    }
  };

  const handleDelete = async (filename: string) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.delete(`${serverUrl}/file/${filename}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage(response.data.message);
      fetchFiles(); // Refresh the list of files after deleting

      // Show success notification
      setNotification({
        type: "success",
        message: "File deleted successfully!",
        description: `The file ${filename} was deleted.`,
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      setNotification({
        type: "error",
        message: "Failed to delete file",
        description: `There was an error deleting the file ${filename}.`,
      });
    }
  };

  const handleInputChange = (
    rowIndex: number,
    column: string,
    value: string
  ) => {
    const updatedData = [...editedData];
    updatedData[rowIndex][column] = value;
    setEditedData(updatedData);

    // Show success notification
    setNotification({
      type: "success",
      message: "Data updated successfully!",
      description: `The ${column} was updated for row ${rowIndex + 1}.`,
    });
  };

  const handleWriteRow = async (row: CSVData, rowIndex: number) => {
    setLoadingWriteIndex(rowIndex);
    try {
      const token = await getAccessTokenSilently();
      const response = await CSVHelper.logRow(row, token, selectedCompanyId);
      if (response) {
        const updatedData = [...editedData];
        updatedData[rowIndex]["Email Content"] = response.emailContent;
        updatedData[rowIndex]["Email Title"] = response.emailTitle;
        updatedData[rowIndex]["Has Sent?"] = "E-Mail is ready";
        setEditedData(updatedData);
        await handleSave(); // Save the updated data

        // Show success notification
        setNotification({
          type: "success",
          message: "Row processed successfully!",
          description: `The row ${rowIndex + 1} was processed and saved.`,
        });
      }
    } catch (error) {
      console.error("Error writing row:", error);
      setNotification({
        type: "error",
        message: "Failed to process row",
        description: `There was an error processing row ${rowIndex + 1}.`,
      });
    } finally {
      setLoadingWriteIndex(null);
    }
  };

  const handleWriteForThem = async () => {
    setStopProcessing(false);
    setLoadingWriteIndex(0); // Start loading
    try {
      const selectedArray = Array.from(selectedRows); // Convert Set to Array
      for (const rowIndex of selectedArray) {
        if (stopProcessing) break; // Check if the stop button was pressed
        setLoadingWriteIndex(rowIndex); // Update loading state for the current row
        await handleWriteRow(editedData[rowIndex], rowIndex);
      }

      // Show success notification
      setNotification({
        type: "success",
        message: "Selected rows processed!",
        description: "All selected rows were processed successfully.",
      });
    } catch (error) {
      console.error("Error executing 'Write for them':", error);
      setNotification({
        type: "error",
        message: "Failed to process selected rows",
        description: "There was an error processing the selected rows.",
      });
    } finally {
      setLoadingWriteIndex(null); // Clear loading state
    }
  };

  const handleStopProcessing = () => {
    setStopProcessing(true);
  };

  const handleSave = async () => {
    if (!currentFilename) {
      setMessage("No file selected to save.");
      setNotification({
        type: "error",
        message: "No file selected",
        description: "Please select a file before saving.",
      });
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const csv = Papa.unparse(editedData);
      const blob = new Blob([csv], { type: "text/csv" });
      const formData = new FormData();
      formData.append("file", blob, currentFilename);

      console.log(`Saving file: ${currentFilename}`);

      const response = await axios.post(`${serverUrl}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage(response.data.message);
      fetchFiles(); // Refresh the list of files after saving
      handleFileClick(currentFilename); // Load the updated file to reflect changes on the frontend

      // Show success notification
      setNotification({
        type: "success",
        message: "File saved successfully!",
        description: `The file ${currentFilename} was saved and reloaded.`,
      });
    } catch (error) {
      console.error("Error saving file:", error);
      setNotification({
        type: "error",
        message: "Failed to save file",
        description: `There was an error saving the file ${currentFilename}.`,
      });
    }
  };

  const handleRowSelect = (rowIndex: number) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(rowIndex)) {
      updatedSelectedRows.delete(rowIndex);
    } else {
      updatedSelectedRows.add(rowIndex);
    }
    setSelectedRows(updatedSelectedRows);

    // Show success notification
    setNotification({
      type: "success",
      message: "Row selection updated!",
      description: `Row ${rowIndex + 1} selection was updated.`,
    });
  };

  const handleSelectAllToggle = () => {
    if (selectedRows.size === editedData.length) {
      setSelectedRows(new Set());

      // Show success notification
      setNotification({
        type: "success",
        message: "Selection cleared!",
        description: "All rows were deselected.",
      });
    } else {
      const allRows = new Set<number>();
      for (let i = 0; i < editedData.length; i++) {
        allRows.add(i);
      }
      setSelectedRows(allRows);

      // Show success notification
      setNotification({
        type: "success",
        message: "All rows selected!",
        description: "All rows were selected successfully.",
      });
    }
  };

  const handleImportSelectedCity = async () => {
    if (!selectedCityFile) {
      setMessage("No city file selected for import.");
      setNotification({
        type: "error",
        message: "No city file selected",
        description: "Please select a city file before importing.",
      });
      return;
    }

    try {
      const response = await fetch(`/${selectedCityFile}`);
      const fileBlob = await response.blob();
      const file = new File([fileBlob], selectedCityFile, {
        type: "text/csv",
      });
      handleFileUpload(file);

      // Show success notification
      setNotification({
        type: "success",
        message: "City file imported!",
        description: `The city file ${selectedCityFile} was imported successfully.`,
      });
    } catch (error) {
      console.error("Error importing city file:", error);
      setNotification({
        type: "error",
        message: "Failed to import city file",
        description: `There was an error importing the city file ${selectedCityFile}.`,
      });
    }
  };

  const handleSendEmail = async (
    email: string,
    subject: string,
    body: string,
    rowIndex: number
  ): Promise<void> => {
    try {
      const token = await getAccessTokenSilently(); // Get the token
      const response = await axios.post(
        `${serverUrl}/send-email`,
        {
          to: email,
          subject,
          text: body,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to headers
          },
        }
      );

      if (response.data.message === "Email sent successfully") {
        console.log("Email sent successfully!");

        // Update the "Has Sent?" column to "Successfully sent"
        const updatedData = [...editedData];
        updatedData[rowIndex]["Has Sent?"] = "Successfully sent";
        setEditedData(updatedData);

        // Save the updated data
        await handleSave();

        // Show success notification
        setNotification({
          type: "success",
          message: "Email sent successfully!",
          description: "The email was sent and the status has been updated.",
        });
      }
    } catch (error) {
      console.error("Error sending email:", error);

      // Show error notification
      setNotification({
        type: "error",
        message: "Failed to send email",
        description: "There was an error sending the email. Please try again.",
      });
    }
  };

  const handleSendForSelected = async () => {
    setStopProcessing(false);
    setLoadingSendIndex(0); // Start loading
    try {
      const selectedArray = Array.from(selectedRows); // Convert Set to Array
      for (const rowIndex of selectedArray) {
        if (stopProcessing) break; // Check if the stop button was pressed
        setLoadingSendIndex(rowIndex); // Update loading state for the current row
        const row = editedData[rowIndex];
        const recipient = row["hqEmail"];
        const subject = row["Email Title"];
        const body = row["Email Content"];

        if (recipient && subject && body) {
          console.log(`Sending email to: ${recipient}`);
          await handleSendEmail(recipient, subject, body, rowIndex);
        } else {
          console.warn(
            `Skipped sending email for row ${rowIndex}: Missing recipient, subject, or body.`
          );
        }
      }

      // Show success notification
      setNotification({
        type: "success",
        message: "Emails sent!",
        description: "All selected emails were sent successfully.",
      });
    } catch (error) {
      console.error("Error sending emails:", error);
      setNotification({
        type: "error",
        message: "Failed to send selected emails",
        description: "There was an error sending the selected emails.",
      });
    } finally {
      setLoadingSendIndex(null); // Clear loading state
    }
  };

  const handleReviewForSelected = async () => {
    setStopProcessing(false);
    try {
      const token = await getAccessTokenSilently(); // Fetch the token here
      const selectedArray = Array.from(selectedRows); // Convert Set to Array

      for (const rowIndex of selectedArray) {
        if (stopProcessing) break; // Check if the stop button was pressed

        setLoadingReviewIndex(rowIndex); // Set loading state for the current row

        const row = editedData[rowIndex];

        // Step 1: Review the Email Content
        const reviewContentResult = await ReviewHelper.reviewContent(
          row,
          token
        ); // Review the content first

        if (reviewContentResult) {
          const updatedData = [...editedData];
          updatedData[rowIndex]["Email Content"] =
            reviewContentResult.reviewedContent;
          setEditedData(updatedData);
          setNotification({
            type: "success",
            message: `Content of row ${rowIndex + 1} reviewed successfully!`,
            description: `The content was reviewed and updated with AI suggestions.`,
          });
          await handleSave(); // Save the updated data after content review
        }

        // Step 2: Review the Email Title
        const reviewTitleResult = await ReviewHelper.reviewTitle(row, token); // Review the title next

        if (reviewTitleResult) {
          const updatedData = [...editedData];
          updatedData[rowIndex]["Email Title"] =
            reviewTitleResult.reviewedTitle;
          updatedData[rowIndex]["Has Sent?"] = "Reviewed"; // Update the "Has Sent?" column to "Reviewed"
          setEditedData(updatedData);
          setNotification({
            type: "success",
            message: `Title of row ${rowIndex + 1} reviewed successfully!`,
            description: `The title was reviewed and updated with AI suggestions.`,
          });
          await handleSave(); // Save the updated data after title review
        }

        setLoadingReviewIndex(null); // Clear loading state for the current row
      }

      setNotification({
        type: "success",
        message: "All selected rows reviewed!",
        description: "The selected rows were reviewed successfully.",
      });
    } catch (error) {
      console.error("Error executing 'Review for them':", error);
      setNotification({
        type: "error",
        message: "Failed to review selected rows",
        description: "There was an error reviewing the selected rows.",
      });
      setLoadingReviewIndex(null); // Clear loading state in case of error
    }
  };

  const handleShortenForSelected = async () => {
    setStopProcessing(false);
    try {
      const token = await getAccessTokenSilently(); // Fetch the token here
      const selectedArray = Array.from(selectedRows); // Convert Set to Array

      for (const rowIndex of selectedArray) {
        if (stopProcessing) break; // Check if the stop button was pressed

        setLoadingShortenIndex(rowIndex); // Set loading state for the current row

        const row = editedData[rowIndex];

        // Shorten the Email Content
        const shortenContentResult = await ShortenHelper.shortenContent(
          row,
          token
        );

        if (shortenContentResult) {
          const updatedData = [...editedData];
          updatedData[rowIndex]["Email Content"] =
            shortenContentResult.shortenedContent;
          updatedData[rowIndex]["Has Sent?"] = "Shortened";
          setEditedData(updatedData);
          setNotification({
            type: "success",
            message: `Content of row ${rowIndex + 1} shortened successfully!`,
            description: `The content was shortened and updated with AI suggestions.`,
          });
          await handleSave(); // Save the updated data after shortening
        }

        setLoadingShortenIndex(null); // Clear loading state for the current row
      }

      setNotification({
        type: "success",
        message: "All selected rows shortened!",
        description: "The selected rows were shortened successfully.",
      });
    } catch (error) {
      console.error("Error executing 'Shorten for them':", error);
      setNotification({
        type: "error",
        message: "Failed to shorten selected rows",
        description: "There was an error shortening the selected rows.",
      });
      setLoadingShortenIndex(null); // Clear loading state in case of error
    }
  };

  const columnKeys = getColumnKeys(editedData);

  return (
    <div className="container mx-auto p-6 min-h-screen">
      {notification && (
        <Notification
          type={notification.type}
          message={notification.message}
          description={notification.description}
        />
      )}
      <div className="col-span-full">
        <div className="mb-4">
          <label
            htmlFor="companyProfileSelect"
            className="block text-sm font-medium text-gray-700"
          >
            Select Company Profile
          </label>
          <select
            id="companyProfileSelect"
            value={selectedCompanyId || ""}
            onChange={(e) => setSelectedCompanyId(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            {profiles.map((profile) => (
              <option key={profile._id} value={profile._id}>
                {profile.companyName}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
          <div className="text-center">
            <PhotoIcon
              aria-hidden="true"
              className="mx-auto h-12 w-12 text-gray-300"
            />
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Upload CSV File <br></br>
              <br></br>
              (Only Use Our CSV Export Template){" "}
              <a
                className="text-black font-bold"
                href="https://drive.google.com/file/d/1FWnnyN_0xbYoMpFhiHDXU1t6A974tW6Z/view?usp=sharing"
                target="_blank"
              >
                Click Here to Get a Sample
              </a>
            </label>

            <div style={{ margin: "20px" }}>
              <label
                style={{
                  display: "inline-block",
                  padding: "10px 20px",
                  backgroundColor: "#4f46e5", // Indigo background color
                  color: "#ffffff", // White text color
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Upload a file
                <input
                  type="file"
                  accept=".csv"
                  style={{
                    display: "none",
                  }}
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      handleFileLoad(e.target.files[0]);
                      handleFileUpload(e.target.files[0]);
                    }
                  }}
                />
              </label>
            </div>
            <p className="text-xs leading-5 text-gray-600">CSV up to 10MB</p>
            <br></br>
            <p className="block text-sm font-medium leading-6 text-gray-900">
              OR
            </p>
            <p className="block text-sm font-medium leading-6 text-gray-900">
              Import a Ready-To-Use Data
            </p>
            <br></br>

            <div className="flex items-center space-x-4">
              <select
                value={selectedCityFile}
                onChange={(e) => setSelectedCityFile(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value="" disabled>
                  Select City File
                </option>
                {cityFiles.map((file) => (
                  <option key={file} value={file}>
                    {file.split("/").pop()}
                  </option>
                ))}
              </select>
              <button
                onClick={handleImportSelectedCity}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Import
              </button>
            </div>
          </div>
        </div>
      </div>
      {message && (
        <div
          className={`my-4 p-4 rounded ${
            message.includes("Error")
              ? "bg-red-200 text-red-800"
              : "bg-green-200 text-green-800"
          }`}
        >
          {message}
        </div>
      )}
      {files.length > 0 && (
        <FileList
          files={files}
          onFileClick={handleFileClick}
          onFileDelete={handleDelete}
        />
      )}
      {editedData.length > 0 && currentFilename && (
        <div className="pt-5 mt-5 overflow-x-auto my-4">
          <div className="mb-2 flex space-x-2">
            {selectedRows.size > 0 && (
              <>
                <button
                  onClick={handleWriteForThem}
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={loadingWriteIndex !== null}
                >
                  {loadingWriteIndex !== null ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      className="text-white"
                    />
                  ) : (
                    "Write Selected"
                  )}
                </button>
                <button
                  onClick={handleSendForSelected}
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={loadingSendIndex !== null}
                >
                  {loadingSendIndex !== null ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      className="text-white"
                    />
                  ) : (
                    "Send Selected"
                  )}
                </button>
                <button
                  onClick={handleReviewForSelected}
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={loadingReviewIndex !== null}
                >
                  {loadingReviewIndex !== null ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      className="text-white"
                    />
                  ) : (
                    "Review Selected"
                  )}
                </button>
                <button
                  onClick={handleShortenForSelected}
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={loadingShortenIndex !== null}
                >
                  {loadingShortenIndex !== null ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      className="text-white"
                    />
                  ) : (
                    "Shorten Selected"
                  )}
                </button>
              </>
            )}
            <button
              onClick={handleSave}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save Changes
            </button>
            {loadingReviewIndex !== null && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                className="text-blue-600 ml-2"
              />
            )}
          </div>

          <CSVTable
            currentData={editedData}
            columnKeys={columnKeys}
            selectedRows={selectedRows}
            handleRowSelect={handleRowSelect}
            handleInputChange={handleInputChange}
            handleWriteRowClick={handleWriteRowClick} // Pass the handler as a prop
            handleSendEmail={async (
              email: string,
              subject: string,
              body: string,
              rowIndex: number
            ) => {
              await handleSendEmail(email, subject, body, rowIndex);
            }}
            loadingRowIndex={loadingRowIndex}
            handleSelectAllToggle={handleSelectAllToggle}
          />
          <button
            onClick={handleSave}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default CSVUpload;
