// src/components/DocxUpload.tsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { PhotoIcon } from "@heroicons/react/24/solid";

interface MdFileMetadata {
  _id: string;
  userId: string;
  filename: string;
  path: string;
  uploadedAt: string;
}

const DocxUpload: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [message, setMessage] = useState<string>("");
  const [files, setFiles] = useState<MdFileMetadata[]>([]);

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    fetchFiles();
  }, [getAccessTokenSilently]);

  const fetchFiles = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get<MdFileMetadata[]>(
        `${serverUrl}/md-files`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching Markdown files:", error);
    }
  };

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(`${serverUrl}/upload-docx`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage(response.data.message);
      fetchFiles(); // Refresh the list of files after uploading
    } catch (error) {
      console.error("Error uploading DOCX file:", error);
      setMessage("Error uploading DOCX file");
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleFileUpload(e.target.files[0]);
    }
  };

  const handleDelete = async (filename: string) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.delete(`${serverUrl}/md-file/${filename}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage(response.data.message);
      fetchFiles(); // Refresh the list of files after deleting
    } catch (error) {
      console.error("Error deleting Markdown file:", error);
      setMessage("Error deleting Markdown file");
    }
  };

  return (
    <div className="my-4 p-4 leading-5 text-center rounded shadow-md">
      {message && (
        <p className="mx-auto leading-5 h-12 w-12 text-gray-400 leading-6 ">
          {/* {message} */}
        </p>
      )}
      {files.length > 0 && (
        <div className="">
          <h2 className="text-gray-400">Your Current Pitch Deck</h2>
          <ul>
            {files.map((file) => (
              <li key={file._id} className="flex items-center">
                <span className="mr-4 text-gray-400">{file.filename}</span>
                <button
                  onClick={() => handleDelete(file.filename)}
                  className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="col-span-full">
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10">
          <div className="text-center">
            <PhotoIcon
              aria-hidden="true"
              className="mx-auto h-12 w-12 text-gray-500"
            />

            <div className="mt-4 flex text-sm leading-6 text-gray-400">
              <label
                htmlFor="file-upload"
                className="leading-5 mx-auto relative cursor-pointer rounded-md font-semibold text-gray-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500"
              >
                <span className="mx-auto leading-5 h-12 w-12 text-gray-400 leading-6 ">
                  Upload your pitch deck
                </span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  accept=".docx, .pdf"
                  onChange={handleFileChange}
                  className="sr-only"
                />
              </label>
            </div>
            <p className="pl-1 text-gray-400">or drag and drop</p>

            <p className="text-xs leading-5 text-gray-400">*PDF or DOCX ONLY</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocxUpload;
