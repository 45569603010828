// src/prompts/emailTemplates.ts
export const exampleEmailContent = `
### Example Email 1:

**Dear [Investor's Name],**

I’m [Your Name], founder of [Your Company/App Name]. We’ve built an AI-driven platform that automates and personalizes job applications, allowing users to send hundreds of hyper-targeted emails with a single click.

### Why Invest?

- **Huge Market:** Millions of job seekers are searching for a better way to get noticed.
- **Innovative Technology:** Our AI outperforms traditional job application methods.
- **Growth Potential:** We’re ready to scale and capture market share.

We’re raising [amount] to accelerate growth. Let’s discuss how you can be part of this transformation.

Best regards,  
[Your Name]  
[Your Position]  
[Your Contact Information]  
[Your Company/App Name]

---

### Example Email 2:

**Dear [Investor's Name],**

I’m [Your Name], and I’m leading [Your Company/App Name] to disrupt the job application process. Our platform uses AI to help job seekers send hundreds of personalized applications in minutes.

### Why Now?

- **Massive Market:** Job seekers globally are underserved by current tools.
- **Scalable Solution:** Our platform is built for rapid growth.
- **Immediate Impact:** We’re seeing strong early demand and user adoption.

We’re raising [amount] to capitalize on this opportunity. I’d love to discuss how we can work together.

Best,  
[Your Name]  
[Your Position]  
[Your Contact Information]  
[Your Company/App Name]

---

### Example Email 3: Focusing on Problem-Solving and Innovation

**Dear [Investor's Name],**

I’m [Your Name], founder of [Your Company/App Name]. We’re making job applications effortless with AI that automates and personalizes outreach, enabling users to send 100s of applications in a fraction of the time.

### Key Points:

- **Efficiency Boost:** Our users save hours, applying to more jobs faster.
- **Proven Demand:** Job seekers are embracing our solution.
- **Scalable Business Model:** Ready for rapid expansion.

We’re seeking [amount] to fuel our growth. I’d love to explore how you can be a part of this journey.

Best regards,  
[Your Name]  
[Your Position]  
[Your Contact Information]  
[Your Company/App Name]

---

### Example Email 4: Showcasing Alignment with the Company's Mission


**Dear [Investor's Name],**

I’m [Your Name], founder of [Your Company/App Name]. We’re using AI to transform how people apply for jobs. Our platform generates and sends hyper-personalized job applications at scale, dramatically improving success rates.

### Why It Matters:

- **AI-Driven:** Our technology is cutting-edge, automating complex tasks.
- **Market Demand:** Job seekers need a more effective way to stand out.
- **Scalability:** Our platform is designed to grow with market needs.

We’re raising [amount] to scale our operations. Let’s discuss how you can be part of this exciting venture.

Best,  
[Your Name]  
[Your Position]  
[Your Contact Information]  
[Your Company/App Name]



### Example Email 5:


**Dear [Investor's Name],**

I’m [Your Name], founder of [Your Company/App Name]. Our platform leverages AI to help job seekers send hundreds of personalized applications quickly and effectively.

### Why Invest?

- **Explosive Growth:** We’re in a high-demand market, poised for rapid expansion.
- **AI Advantage:** Our technology outpaces traditional job application methods.
- **Strategic Timing:** With early traction, we’re ready to scale.

We’re seeking [amount] to fuel this growth. I’d welcome the opportunity to discuss how you can join us on this journey.

Best regards,  
[Your Name]  
[Your Position]  
[Your Contact Information]  
[Your Company/App Name]
`;

export const emailTemplateContent = `
### Investor Email Body Structure:

1. - Craft a compelling and concise subject that grabs attention.
   - Example: "Revolutionizing Job Applications – Investment Opportunity"

2. - Start with a personalized greeting.
   - Example: "Dear [Investor's Name],"

3. - Introduce yourself and your company with a strong hook.
   - Example: "I’m [Your Name], founder of [Your Company/App Name]. We’re transforming the job application process using AI to help job seekers send hundreds of hyper-personalized applications effortlessly."

4. - Clearly articulate the problem your company is solving.
   - Example: "The traditional job application process is inefficient and overwhelming, leaving qualified candidates unnoticed."

5. - Provide a brief overview of your solution and how it addresses the problem.
   - Example: "Our platform automates the application process, allowing users to send tailored, AI-generated emails to potential employers, significantly improving their chances of success."

6. - Highlight the market potential and why now is the perfect time for this solution.
   - Example: "With millions of job seekers worldwide, the demand for more efficient application tools is rapidly growing."

7. - Explain the investment opportunity and what you’re seeking.
   - Example: "We’re raising [amount] to accelerate growth, enhance our technology, and capture a significant share of this underserved market."

8. - Suggest a next step, like scheduling a meeting or call.
   - Example: "I’d love to discuss how you can be part of this exciting journey. Are you available for a call next week?"

9. - End with a polite and professional closing, including your contact information.
   - Example: "Thank you for considering this opportunity. Looking forward to your response. Best regards, [Your Name] [Your Contact Information]"

`;
