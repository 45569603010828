import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
  exampleEmailContent,
  emailTemplateContent,
} from "../prompts/emailTemplates";

const serverUrl = process.env.REACT_APP_SERVER_URL;

// Default prompt values
const defaultEmailTemplateContent = `${emailTemplateContent}`;
const defaultExampleEmailContent = `${exampleEmailContent}`;
const defaultEmailTitleTemplateContent =
  "Based on the following email content, generate an email title: ${emailContent}. ### Example Subject Lines: - Inspired by [Startup Name]s Mission Excited about [Startup Name]'s Innovations Do not add any title etc. Just give me the plain email subject/title";
const defaultCustomPrompt = `I have a company that I want to raise some money from investors.
Based on my company introduction and the investor details, generate an introductory email for investment.
Write the plain email, do not add anything, any explanation, any title or anything else except email.
Do not write any markdown symbol.
Do not write email subject.`;

const PromptModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [emailTemplateContent, setEmailTemplateContent] = useState("");
  const [exampleEmailContent, setExampleEmailContent] = useState("");
  const [emailTitleTemplateContent, setEmailTitleTemplateContent] =
    useState("");
  const [customPrompt, setCustomPrompt] = useState("");
  const [message, setMessage] = useState("");

  const [editMode, setEditMode] = useState({
    emailTemplateContent: false,
    exampleEmailContent: false,
    emailTitleTemplateContent: false,
    customPrompt: false,
  });

  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  // Function to reset the prompts to their default values
  const useDefaultPrompts = () => {
    setEmailTemplateContent(defaultEmailTemplateContent);
    setExampleEmailContent(defaultExampleEmailContent);
    setEmailTitleTemplateContent(defaultEmailTitleTemplateContent);
    setCustomPrompt(defaultCustomPrompt);
  };

  useEffect(() => {
    // Fetch user's current prompt data here if needed
  }, []);

  const handleSavePrompts = async () => {
    try {
      const token = await getAccessTokenSilently();
      if (user && user.sub) {
        const dataToUpdate: any = {
          userId: user.sub,
          name: user.name,
          email: user.email,
          gmailUsername: user.gmailUsername || "",
          gmailPassword: user.gmailPassword || "",
        };

        if (emailTemplateContent)
          dataToUpdate.emailTemplateContent = emailTemplateContent;
        if (exampleEmailContent)
          dataToUpdate.exampleEmailContent = exampleEmailContent;
        if (emailTitleTemplateContent)
          dataToUpdate.emailTitleTemplateContent = emailTitleTemplateContent;
        if (customPrompt) dataToUpdate.customPrompt = customPrompt;

        const response = await axios.post(
          `${serverUrl}/update-profile`,
          dataToUpdate,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setMessage("Prompts updated successfully!");
        onClose(); // Close the modal after saving
      } else {
        setMessage("Error: User data is missing.");
      }
    } catch (error) {
      setMessage("Error updating prompts.");
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Panel className="bg-white p-6 rounded shadow-xl max-w-lg w-full relative">
          <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
            Edit Prompts
          </Dialog.Title>

          {/* Use Default Prompt Button */}
          <button
            onClick={useDefaultPrompts}
            className="absolute top-4 right-4 bg-gray-500 text-white px-3 py-1 rounded hover:bg-gray-600"
          >
            Use Default Prompt
          </button>

          <div className="mt-4">
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Email Template Content
              </label>
              {editMode.emailTemplateContent ? (
                <textarea
                  value={emailTemplateContent}
                  onChange={(e) => setEmailTemplateContent(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter your email template content..."
                />
              ) : (
                <div
                  className="p-2 border border-gray-300 rounded cursor-pointer"
                  onClick={() =>
                    setEditMode((prev) => ({
                      ...prev,
                      emailTemplateContent: true,
                    }))
                  }
                >
                  {emailTemplateContent || "Click to edit"}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Example Email Content
              </label>
              {editMode.exampleEmailContent ? (
                <textarea
                  value={exampleEmailContent}
                  onChange={(e) => setExampleEmailContent(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter your example email content..."
                />
              ) : (
                <div
                  className="p-2 border border-gray-300 rounded cursor-pointer"
                  onClick={() =>
                    setEditMode((prev) => ({
                      ...prev,
                      exampleEmailContent: true,
                    }))
                  }
                >
                  {exampleEmailContent || "Click to edit"}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Custom Email Title Prompt
              </label>
              {editMode.emailTitleTemplateContent ? (
                <textarea
                  value={emailTitleTemplateContent}
                  onChange={(e) => setEmailTitleTemplateContent(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter your custom email title prompt..."
                />
              ) : (
                <div
                  className="p-2 border border-gray-300 rounded cursor-pointer"
                  onClick={() =>
                    setEditMode((prev) => ({
                      ...prev,
                      emailTitleTemplateContent: true,
                    }))
                  }
                >
                  {emailTitleTemplateContent || "Click to edit"}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Custom OpenAI Prompt
              </label>
              {editMode.customPrompt ? (
                <textarea
                  value={customPrompt}
                  onChange={(e) => setCustomPrompt(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter your custom prompt here..."
                />
              ) : (
                <div
                  className="p-2 border border-gray-300 rounded cursor-pointer"
                  onClick={() =>
                    setEditMode((prev) => ({
                      ...prev,
                      customPrompt: true,
                    }))
                  }
                >
                  {customPrompt || "Click to edit"}
                </div>
              )}
            </div>
            {message && <p className="mt-4">{message}</p>}
          </div>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={handleSavePrompts}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Save
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default PromptModal;
