import React from "react";
import { Button } from "./Button"; // Adjust the path as necessary
import { Container } from "./Container"; // Adjust the path as necessary
import backgroundImage from "../images/background-call-to-action.jpg"; // Adjust the path as necessary
import { useAuth0 } from "@auth0/auth0-react";

export function CallToAction() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2347}
        height={1244}
      />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Start Reaching Out to Investors Today{" "}
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Don't miss out on securing the funding you need. Get started with
            our app and send your first batch of investor pitches today.
          </p>
          {!isAuthenticated ? (
            <a
              className="ml-3"
              href="#"
              onClick={() =>
                loginWithRedirect({
                  appState: { returnTo: "/dashboard" },
                })
              }
            >
              Start Your Free Trial
            </a>
          ) : (
            <a className="ml-3" href="/"></a>
          )}
        </div>
      </Container>
    </section>
  );
}
