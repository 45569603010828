// src/helpers/csvHelper.ts
import axios from "axios";
import { fetchWebsiteMarkdown } from "./websiteHelper";
import {
  exampleEmailContent as defaultExampleEmailContent,
  emailTemplateContent as defaultEmailTemplateContent,
} from "../prompts/emailTemplates";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export interface CSVData {
  [key: string]: string;
}

export class CSVHelper {
  static async logRow(
    row: CSVData,
    token: string,
    companyId: string | null
  ): Promise<{ emailContent: string; emailTitle: string } | null> {
    console.log("Row data:", row);

    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey) {
      console.error("OpenAI API key not found.");
      return null;
    }

    try {
      // Fetch user data to check for custom prompts
      const userResponse = await axios.get(`${serverUrl}/user-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const user = userResponse.data;
      const emailTemplateContent =
        user.emailTemplateContent || defaultEmailTemplateContent;
      const exampleEmailContent =
        user.exampleEmailContent || defaultExampleEmailContent;
      const { customPrompt } = userResponse.data;

      const defaultEmailTitleTemplateContent =
        "Based on the following email content, generate an email title: ${emailContent}. ### Example Subject Lines: - Inspired by [Startup Name]s Mission Excited about [Startup Name]'s Innovations Do not add any title etc. Just give me the plain email subject/title";

      const emailTitleTemplateContent =
        user.emailTitleTemplateContent || defaultEmailTitleTemplateContent;

      const userPrompt =
        customPrompt ||
        `I have a company that I want to raise some money from investors.
      Based on my company introduction and the investor details, generate an introductory email for investment.
      Write the plain email, do not add anything, any explanation, any title or anything else except email.
      Do not write any markdown symbol.
      Do not write email subject.`;

      // Fetching user's resume
      const resumeResponse = await axios.get(`${serverUrl}/md-files`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!resumeResponse.data.length) {
        console.error("No resume found for the user.");
        return null;
      }

      const resumeMarkdown = await axios.get(resumeResponse.data[0].url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const websiteUrl = row["investorWebsite"];
      const websiteContentMarkdown = await fetchWebsiteMarkdown(websiteUrl);

      // Fetch user's selected company profile if provided
      let companyProfile = null;
      if (companyId) {
        const companyProfileResponse = await axios.get(
          `${serverUrl}/company-profile/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        companyProfile = companyProfileResponse.data;
      }

      // Prepare OpenAI prompt with resume, company data, website content, and company profile
      const openaiPrompt = `${userPrompt}
                            Use this structure: ${emailTemplateContent}
                            A good example: ${exampleEmailContent}
                            Here is my company pitch deck: ${
                              resumeMarkdown.data
                            }
                            Here is the investor data: ${JSON.stringify(row)}
                            This is the website content of investor: ${websiteContentMarkdown}
                            ${
                              companyProfile
                                ? `Here is my company profile: ${JSON.stringify(
                                    companyProfile
                                  )} and my name is ${JSON.stringify(
                                    companyProfile.whoUse
                                  )}`
                                : ""
                            }`;

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o",
          messages: [
            {
              role: "system",
              content: "You are a helpful assistant.",
            },
            {
              role: "user",
              content: openaiPrompt,
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      console.log("OpenAI response:", response.data.choices[0].message.content);
      const emailContent = response.data.choices[0].message.content;

      // Asking for an email title based on the generated content
      const titleResponse = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o",
          messages: [
            {
              role: "system",
              content: "You are a helpful assistant.",
            },
            {
              role: "user",
              content: `Based on the following email content, generate an email title: ${emailContent} use this structure: ${emailTitleTemplateContent}`,
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      const emailTitle = titleResponse.data.choices[0].message.content;

      return { emailContent, emailTitle };
    } catch (error) {
      console.error(
        "Error calling OpenAI API:",
        (error as any).response?.data || (error as any).message
      );
      return null;
    }
  }
}
