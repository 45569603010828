import React, { useState } from "react";
import { CSVData } from "../helpers/csvHelper";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faSpinner,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

// Utility function to handle conditional class names
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

// Define the properties that the CSVTable component will accept
interface CSVTableProps {
  currentData: CSVData[];
  columnKeys: string[];
  selectedRows: Set<number>;
  handleRowSelect: (rowIndex: number) => void;
  handleInputChange: (rowIndex: number, column: string, value: string) => void;
  handleWriteRowClick: (row: CSVData, rowIndex: number) => void; // <-- Updated to use the correct handler
  handleSendEmail: (
    email: string,
    subject: string,
    body: string,
    rowIndex: number
  ) => Promise<void>; // Assuming handleSendEmail is async
  loadingRowIndex: number | null;
  handleSelectAllToggle: () => void;
}

// Main CSVTable component
const CSVTable: React.FC<CSVTableProps> = ({
  currentData,
  columnKeys,
  selectedRows,
  handleRowSelect,
  handleInputChange,
  handleWriteRowClick, // <-- Use the passed handler
  handleSendEmail,
  loadingRowIndex,
  handleSelectAllToggle,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalRowIndex, setModalRowIndex] = useState<number | null>(null);

  // State for handling email sending loading indicator
  const [sendingEmailIndex, setSendingEmailIndex] = useState<number | null>(
    null
  );

  // Function to handle clicking on the "See" button to edit email content
  const handleSeeButtonClick = (content: string, rowIndex: number) => {
    setModalContent(content);
    setModalRowIndex(rowIndex);
    setShowModal(true);
  };

  // Function to save the edited content and close the modal
  const handleModalSave = () => {
    if (modalRowIndex !== null) {
      handleInputChange(modalRowIndex, "Email Content", modalContent);
    }
    setShowModal(false);
  };

  // Determine if all rows are selected
  const isAllSelected =
    currentData.length > 0 && selectedRows.size === currentData.length;

  // State for handling editable cells
  const [editableCell, setEditableCell] = useState<{
    rowIndex: number | null;
    column: string | null;
  }>({ rowIndex: null, column: null });

  // Handle cell click for editable fields
  const handleCellClick = (rowIndex: number, column: string) => {
    setEditableCell({ rowIndex, column });
  };

  // Handle blur event to stop editing
  const handleCellBlur = () => {
    setEditableCell({ rowIndex: null, column: null });
  };

  // Function to handle sending email with loading indicator
  const handleEmailSendClick = async (
    email: string,
    subject: string,
    body: string,
    rowIndex: number
  ) => {
    setSendingEmailIndex(rowIndex); // Set the current row as loading
    await handleSendEmail(email, subject, body, rowIndex);
    setSendingEmailIndex(null); // Reset loading state after sending
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full border-separate border-spacing-0">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b bg-gray-100">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        checked={isAllSelected}
                        onChange={handleSelectAllToggle}
                      />
                    </th>
                    {columnKeys.map((key, index) => (
                      <th
                        key={index}
                        className="py-2 px-4 border-b bg-gray-100"
                      >
                        {key}
                      </th>
                    ))}
                    <th className="py-2 px-4 border-b bg-gray-100">Send</th>
                    <th className="py-2 px-4 border-b bg-gray-100">Write</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="hover:bg-gray-50">
                      <td className="py-2 px-4 border-b">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          checked={selectedRows.has(rowIndex)}
                          onChange={() => handleRowSelect(rowIndex)}
                        />
                      </td>
                      {columnKeys.map((key, cellIndex) => (
                        <td
                          key={cellIndex}
                          className={classNames(
                            cellIndex !== key.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            key === "companyName" ||
                              key === "hqEmail" ||
                              key === "investorWebsite" ||
                              key === "Email Title"
                              ? "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 text-left sm:pl-6 lg:pl-8 overflow-hidden max-w-xs truncate"
                              : "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                          )}
                          onClick={() =>
                            key === "hqEmail" ||
                            key === "investorWebsite" ||
                            key === "Email Title"
                              ? handleCellClick(rowIndex, key)
                              : undefined
                          }
                          style={{ maxWidth: "150px" }}
                        >
                          {key === "Email Content" && row[key] ? (
                            <button
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick={() =>
                                handleSeeButtonClick(row[key] || "", rowIndex)
                              }
                            >
                              See
                            </button>
                          ) : key === "Email Content" ? (
                            <span className="text-gray-400">N/A</span>
                          ) : editableCell.rowIndex === rowIndex &&
                            editableCell.column === key ? (
                            <input
                              type="text"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={row[key] || ""}
                              onChange={(e) =>
                                handleInputChange(rowIndex, key, e.target.value)
                              }
                              onBlur={handleCellBlur}
                              autoFocus
                            />
                          ) : (
                            row[key] || ""
                          )}
                        </td>
                      ))}
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        <button
                          onClick={() =>
                            handleEmailSendClick(
                              row["hqEmail"],
                              row["Email Title"],
                              row["Email Content"],
                              rowIndex // Pass the rowIndex here
                            )
                          }
                          className={`text-blue-600 hover:underline ${
                            sendingEmailIndex === rowIndex
                              ? "cursor-not-allowed"
                              : ""
                          }`}
                          disabled={sendingEmailIndex === rowIndex}
                        >
                          {sendingEmailIndex === rowIndex ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            <FontAwesomeIcon icon={faEnvelope} />
                          )}
                        </button>
                      </td>
                      <td className="py-2 px-4 border-b">
                        <button
                          onClick={() => handleWriteRowClick(row, rowIndex)}
                          className={`text-blue-600 hover:underline ${
                            loadingRowIndex === rowIndex
                              ? "cursor-not-allowed"
                              : ""
                          }`}
                          disabled={loadingRowIndex === rowIndex}
                        >
                          {loadingRowIndex === rowIndex ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            <FontAwesomeIcon icon={faPlay} />
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Modal for editing Email Content */}
              <Dialog
                open={showModal}
                onClose={setShowModal}
                className="relative z-10"
              >
                <DialogBackdrop
                  transition
                  className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                      transition
                      className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                      <div className="mt-3 text-center sm:mt-5">
                        <DialogTitle
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Email Content
                        </DialogTitle>
                        <div className="mt-2">
                          <textarea
                            value={modalContent}
                            onChange={(e) => setModalContent(e.target.value)}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            rows={5}
                          />
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          onClick={handleModalSave}
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => setShowModal(false)}
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        >
                          Cancel
                        </button>
                      </div>
                    </DialogPanel>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CSVTable;
